<template>
    <v-container>
      <!-- <v-card class="justify-center"> -->
          <v-container class="justify-center">
            <v-card-title primary-title class="justify-center green--text">
                Editar avaliação
            </v-card-title>
                      
            <center><v-btn class="white--text" style="background-color: #009263;" @click="editarAvaliacao()" :disabled="!canSave"> Confirmar Alterações </v-btn></center>
           
            <v-row>
              <v-col cols="12" sm="3" md="1">
                <v-text-field outlined color="#009263" label="Número" placeholder="Número" v-model="aluno.numero" type="number" :readonly="true" hide-details/>
              </v-col>
              <v-col cols="12" sm="9" md="11">
                <v-text-field outlined color="#009263" label="Nome" placeholder="Nome" v-model="aluno.nome" :readonly="true" hide-details/>
              </v-col>
            </v-row>
                       
            
            <v-row>
              <v-col cols="12" md="6">
                <v-combobox outlined color="#009263" item-text="descricao"
                    v-model="avaliacao.notaFinalAnoAnterior" name="Nota final do ano anterior" label="Nota final do ano anterior" :items="tiposAvaliacao"
                    hide-details
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="6">
                <v-combobox outlined color="#009263" item-text="descricao"
                    v-model="avaliacao.notaFinalAnoAtual" name="Nota final do ano atual" label="Nota final do ano atual" :items="tiposAvaliacao"
                    hide-details
                ></v-combobox>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field outlined color="#009263" label="Nota do teste diagnóstico Hypatiamat" placeholder="" type="number" :min="0" :max="100" v-model="avaliacao.notaDiagnosticoHypatiamat" :rules="[isIntegerBetween0And100]" hide-details/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field outlined color="#009263" label="Nota do teste final Hypatiamat" placeholder="" type="number" :min="0" :max="100" v-model="avaliacao.notaFinalHypatiamat" :rules="[isIntegerBetween0And100]" hide-details/>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" sm="4">
                <v-checkbox outlined color="#009263" label="Tem dificuldade?" v-model="avaliacao.comDificuldade" hide-details/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox outlined color="#009263" label="Tem medidas seletivas?" v-model="avaliacao.medidasSeletivas" @change="medidasSeletivasChange()" hide-details/>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox outlined color="#009263" label="Tem medidas adicionais?" v-model="avaliacao.medidasAdicionais" @change="medidasAdicionaisChange()" hide-details/>
              </v-col>
            </v-row>

            <v-textarea class="pa-0" color="#009263" outlined
                    v-model="avaliacao.observacoes" name="Opinião/Observações" label="Opinião/Observações" hide-details
            ></v-textarea>

            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field outlined color="#009263" label="APP - ano anterior - NTRC" placeholder="" type="number" v-model="avaliacao.appsNTRCAnoAnterior" :readonly="true" hide-details/>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field outlined color="#009263" label="APP - ano anterior - % Acerto" placeholder="" type="number" v-model="avaliacao.appsAcertoAnoAnterior" :readonly="true" hide-details/>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field outlined color="#009263" label="Jogos - ano anterior - Jogos concluídos" placeholder="" type="number" v-model="avaliacao.jogosConcluidosAnoAnterior" :readonly="true" hide-details/>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field outlined color="#009263" label="APP - ano atual - NTRC" placeholder="" type="number" v-model="avaliacao.appsNTRCAnoAtual" :readonly="true" hide-details/>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field outlined color="#009263" label="APP - ano atual - % Acerto" placeholder="" type="number" v-model="avaliacao.appsAcertoAnoAtual" :readonly="true" hide-details/>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field outlined color="#009263" label="Jogos - ano atual - Jogos concluídos" placeholder="" type="number" v-model="avaliacao.jogosConcluidosAnoAtual" :readonly="true" hide-details/>
              </v-col>
            </v-row>

            <v-text-field outlined color="#009263" label="Última data de atualização" placeholder="" type="text" v-model="avaliacao.lastDate" :readonly="true" hide-details/>

          </v-container>
      <!-- </v-card> -->
    </v-container>
  </template>
  
  
  
  <script>
  import axios from "axios"
  import Swal from 'sweetalert2'
  const h = require("@/config/hosts").hostAPI
  
    export default {
      data(){
        return {
          token: "",
          aluno: {},
          avaliacao: {},
          utilizador:{},
          tiposAvaliacao: [],
          reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
          number0or1: v  => {
            if (!isNaN(parseInt(v)) && (v == 0 || v == 1)) return true;
            return 'Tem que ser 0 ou 1';
          },
          isInteger: v  => {
            if (!isNaN(parseInt(v))) return true;
            return 'Tem que ser um inteiro';
          },
          isIntegerBetween0And100: v => {
            const intValue = parseInt(v);
            if (!isNaN(intValue) || v == null || v == ''){
              if((intValue >= 0 && intValue <= 100) || v == null || v == ''){
                return true;
              }
              else {
                return 'Tem que estar compreendido entre 0 e 100.'
              }
            }
            return 'Tem que ser um inteiro'; 
          }
        }
      },
      computed:{
        canSave(){
          let value = true;
          if(this.avaliacao){
            if(!this.isValueBetween1And100OrNull(this.avaliacao.notaFinalAnoAnterior)){
              value = false;
            }
            else if(!this.isValueBetween1And100OrNull(this.avaliacao.notaFinalAnoAtual)){
              value = false;
            }
            else if(!this.isValueBetween1And100OrNull(this.avaliacao.notaDiagnosticoHypatiamat)){
              value = false;
            }
            else if(!this.isValueBetween1And100OrNull(this.avaliacao.notaFinalHypatiamat)){
              value = false;
            } 
          }
          return value;
        }
      },
      props:["alunoProp", "avaliacaoProp", "tiposAvaliacaoProp"],
      created: async function(){
          this.token = localStorage.getItem("token");
          const user = localStorage.getItem("utilizador");
          if(user){
            this.utilizador = JSON.parse(user);
          }
          this.avaliacao = JSON.parse(JSON.stringify(this.avaliacaoProp));
          this.aluno = JSON.parse(JSON.stringify(this.alunoProp));
          this.tiposAvaliacao = this.tiposAvaliacaoProp || [];
          console.log(this.tiposAvaliacao);
      },
      methods: {
        editarAvaliacao: function(){
            axios.put(h + "avaliacoes/" + this.avaliacao.id + "/valoresProf?token=" + this.token, this.avaliacao)
                    .then(dados => {
                        Swal.fire({
                            icon: 'success',
                            text: "Dados alterados com sucesso!",
                            confirmButtonColor: '#009263'
                        });
                        this.$emit("alteracao", {user: this.aluno.user, avaliacao: this.avaliacao})
                    })
                    .catch(error => 
                        Swal.fire({
                            icon: 'error',
                            text: "Não foi possível guardar as alterações.",
                            confirmButtonColor: '#009263'
                            })
                    );
        },
        isValueBetween1And100OrNull(intValue){
          let result = false;
          if ((!isNaN(intValue)) || intValue == null || intValue == ''){
            if((intValue >= 0 && intValue <= 100) || intValue == null || intValue == ''){
              result = true;
            }
          }
          return result;
        },
        medidasSeletivasChange(){
          if(this.avaliacao && this.avaliacao.medidasSeletivas){
            this.avaliacao.medidasAdicionais = false; 
          }
        },
        medidasAdicionaisChange(){
          if(this.avaliacao && this.avaliacao.medidasAdicionais){
            this.avaliacao.medidasSeletivas = false; 
          }
        }
      }
    }
  </script>