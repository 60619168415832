<template>
<div class="grey lighten-3"> 

    <Navbar @refreshLogout="refreshLogout" @miniEvent="(value)=>{this.mini=value}" :mensagensLer="mensagensLer" :storage="storage"/>


    <keep-alive :include="cacheItens" :max="10">
      <router-view :key="$route.fullPath" 
          @refreshVistas="mensagensLer = 0" @refreshLogout="refreshLogout"></router-view>
    </keep-alive>
    <!--
    <v-main v-else class="grey lighten-3">
      <p :style="styleP"> Dado que se encontra no telemóvel ou num dispositivo pequeno, para visualizar os dados de forma clara, <b> minimize a barra de navegação </b> através do botão <span :style="styleP">&#8918;</span> . </p> 
    </v-main>
    -->
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Views from '@/components/View.vue'
import Swal from 'sweetalert2'
import axios from "axios"
const h = require("@/config/hosts").hostAPI
const hostCromos = require("@/config/hosts").hostCromos
const hostTpcs = require("@/config/hosts").hostAPITpcs

export default {
  components:{
    Navbar,
    Views
  },
  props:["storage"],
  data(){
      return {
      color: "#FF0000",
      mini:true,
      miniVariant: false,
      windowWidth: 0,
      styleP: 'font-size:20px',
      mensagensLer:0,
      oldNovasMensagensLer: 0, 
      token: "",
      interval: undefined,
      utilizador: {},
      cacheItens: ['JogosMunicipios', 'JogosEscolas', 'JogosProfessores', 'JogosTurmas',
                   'AppsMunicipios', 'AppsEscolas', 'AppsProfessores', 'AppsTurmas',
                   'EstatisticasMunicipios', 'EstatisticasAgrupamentos', 'EstatisticasProfessores',
                   'CampeonatosMunicipios', 'CampeonatosAgrupamentos', 'CampeonatosProfessores', 'CampeonatosTurmas',
                   'RankingApps', 'RankingAppsAdmin', 'RankingAppsAgrupamento', 'RankingAppsMunicipio',
                   'RankingJogos', 'RankingJogosAdmin', 'RankingJogosAgupamento', 'RankingJogosMunicipio']
    }
  },
  computed: {
    mobile() {
      if(localStorage.getItem("token") && localStorage.getItem("utilizador") && localStorage.getItem("type")){
        if (this.$vuetify.breakpoint.xs) return this.mini
        return true
      }
      else {
        this.refreshLogout()
        return true
      }
    },
  },
  created: async function(){
    this.utilizador = JSON.parse(localStorage.getItem("utilizador"))
    this.token = localStorage.getItem("token")
    this.windowWidth = window.innerWidth
    this.size();
    this.getAlunoNotificacoes();
  },
  mounted: function(){
    window.onresize = () => {
      this.size()
    }
  },
  methods:{
    refreshLogout : function(){
      clearInterval(this.interval)
      this.$emit("refreshLogout")
    },
    size(){
        if(window.innerWidth > 500){
          this.styleP = 'font-size:20px'
        }
        else if(window.innerWidth > 400){
          this.styleP = 'font-size:16px'
        }
        else{
          this.styleP = 'font-size:9px'
        }
    },
    getNovosCromos: async function(){
      let result = '';
      var response = await axios.get(hostCromos + "novos/alunos/" + this.utilizador.user + "?token=" + this.token)
      var novosCromos = response.data.novosCromos
      if(novosCromos.length > 0){
        var htmlCode = '<p><center><h1> Parabéns! </h1></center></p>'
        for(var i = 0; i < novosCromos.length; i++){
          var cromoGanho = novosCromos[i]
          if(cromoGanho.freq) htmlCode += '<p> Ganhou o cromo <b>' + cromoGanho.numero + '</b> ' + cromoGanho.freq + ' vez(es). </p>'
          else htmlCode += '<p> Ganhou o cromo <b>' + cromoGanho.numero + '</b>. </p>'
        }
        result = htmlCode;
        // Swal.fire({
        //   icon: 'success',
        //   html: htmlCode,
        //   confirmButtonColor: '#009263'
        // })
      }
      return result;
    }, 
    atualizaNovasMensagens: async function(){
      let result = '';
      var response = await axios.get(h + "mensagens/alunos/" + this.utilizador.user + "/number/naovistas?token=" + this.token)
      this.mensagensLer =  response.data.number 
      this.oldNovasMensagensLer = this.mensagensLer
      if(this.mensagensLer > 0){
        result = `<p>Tem ${this.mensagensLer} mensagens por ler.</p>`
        // Swal.fire({
        //   icon: 'warning',
        //   text: 'Tem ' + this.mensagensLer + ' mensagens por ler.',
        //   confirmButtonColor: '#009263'
        // })
      }
      return result;
    },
    getNovosTpcs: async function(){
      let result = '';
      let response = await axios.get(`${hostTpcs}alunos/${this.utilizador.user}/por_resolver?token=${this.token}`);
      const data = response.data;
      if(data && data.length){
        const length = data.length;
        const plural = length > 1 ? 's' : '';
        let htmlMessage = `<p> Tem ${length} trabalho${plural} de casa por resolver:</p>`;
        for(let i = 0; i < length; i++){
          const tpc = data[i];
          const divWithStyle= "<div style='border: 2px solid green !important; margin-top: 10px; padding: 5 5;'>";
          htmlMessage += `${divWithStyle}<center><p><b>${tpc.tagname}:</b></p></center> <p> Limite de entrega: <b>${tpc.dataFim}</b> </p> <p> Nº de tentativas: <b>${tpc.tentativas}</b></p></div>`
        }
        result = htmlMessage;
        // Swal.fire({
        //   icon: 'warning',
        //   html: htmlMessage,
        //   confirmButtonColor: '#009263'
        // });
      }
      return result;
    },
    getAlunoNotificacoes: async function(){
      if(this.utilizador.type == 10){
        const htmlCromos = await this.getNovosCromos();
        const htmlNovasMensagens = await this.atualizaNovasMensagens();
        const htmlNovosTpcs = await this.getNovosTpcs();
        this.alerta(htmlNovasMensagens, htmlCromos, htmlNovosTpcs);
      }
    },
    alerta(htmlNovasMensagens, htmlCromos, htmlNovosTpcs){
      if(htmlNovasMensagens && htmlNovasMensagens.length){
        this.swalFire('warning', htmlNovasMensagens)
            .then(() => {
              this.treatHtmlCromosAndTpcs(htmlCromos, htmlNovosTpcs);
              return;
            })
      }
      else {
        this.treatHtmlCromosAndTpcs(htmlCromos, htmlNovosTpcs);
        return;
      }


      // let htmlCode = (htmlNovasMensagens || '');
      // htmlCode += ((htmlCode && htmlCode.length) ? '<hr>' : '') + (htmlCromos || '');
      // htmlCode += ((htmlCode && htmlCode.length) ? '<hr>' : '') + (htmlNovosTpcs || '');
      // if(htmlCode && htmlCode.length){
      //   Swal.fire({
      //     icon: 'warning',
      //     html: htmlMessage,
      //     confirmButtonColor: '#009263'
      //   });
      // }
    },
    treatHtmlCromosAndTpcs(htmlCromos, htmlNovosTpcs){
      if(htmlCromos && htmlCromos.length){
        this.swalFire('success', htmlCromos)
            .then(() => {
              if(htmlNovosTpcs && htmlNovosTpcs.length){
                this.swalFire('warning', htmlNovosTpcs)
                    .then(() => {});
              }
            })
      }
      else if(htmlNovosTpcs && htmlNovosTpcs.length){
        this.swalFire('warning', htmlNovosTpcs)
            .then(() => {});
      }
    },
    swalFire(icon, htmlCode, color = null){
      return Swal.fire({
        icon: icon ? icon : 'warning',
        html: htmlCode ? htmlCode : '',
        confirmButtonColor: color ? color : '#009263'
      });
    }
    // formatHtml(htmlCode, useSeparator){
    //   let result = '';
    //   if(htmlCode){
    //     result += htmlCode;
    //     result += (useSeparator ? '<hr>' : '');
    //   }
    //   return result;
    // }
  }

}
</script>
