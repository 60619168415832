<template>
    <div v-if="isVisible" class="text-center">
    <v-menu
        open-on-hover
        end
        offset-y
        style="max-width: 112px;"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="#009263"
            dark
            v-bind="attrs"
            v-on="on"
            >
            Exportar
            </v-btn>
        </template>
        <div style=" background-color: white;">
          <v-btn v-if="isPDFEnabled" plain text style="color: #009263; min-width: 112px;" @click="PDFClicked()"><v-icon> mdi-file-pdf-box </v-icon> PDF</v-btn>
          <br v-if="isPDFEnabled">
          <vue-json-to-csv
              v-if="isCSVEnabled"
              :json-data="itemsCSVToExport"
              :labels="fieldsToCSVExport"
              :csvTitle="filename"
              :separator="';'"
          >
              <v-btn plain text style="color: #009263; min-width: 112px;"><v-icon> mdi-file-delimited-outline </v-icon> CSV</v-btn>
          </vue-json-to-csv>
        </div>
        </v-menu>
</div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv';
export default {
  components: {
    VueJsonToCsv
  },
  props: [
    // if component is visible or not
    'isVisibleProp', 
    // if PDF button is visible or not
    'isPDFEnabledProp', 
    // if CSV button is visible or not
    'isCSVEnabledProp',
    // list of items to export by CSV format
    'itemsCSVToExportProp',
    // list of fields to export on CSV {'header_name': 'attribute_in_object_list', ...}
    'fieldsToCSVExportProp',
    // filename to export result
    'filenameProp'
  ],
  watch : {
    isVisibleProp: function (value) {
        this.isVisible = !!value;
    },
    isPDFEnabledProp: function(value){
        this.isPDFEnabled = !!value;
    }, 
    isCSVEnabledProp: function(value){
        this.isCSVEnabled = !!value;
    },
    itemsCSVToExportProp: function(value){
        this.itemsCSVToExport = value || [];
    },
    fieldsToCSVExportProp: function(value){
        console.log('fieldsToCSVExportProp changed: ');
        console.log(value);
        this.fieldsToCSVExport = value;
    },
    filenameProp: function(value){
        this.filename = value;
    }
  },
  computed: {
    CSVFilename(){
        return `${this.filename}.xls`;
    }
  },
  data(){
    return {
        isVisible: false,
        isPDFEnabled: true,
        isCSVEnabled: true,
        itemsCSVToExport: [],
        fieldsToCSVExport: {},
        filename: 'Documento'
    }
  },
  created() {
    this.isVisible = !!this.isVisibleProp;
    this.isPDFEnabled = !!this.isPDFEnabledProp;
    this.isCSVEnabled = !!this.isCSVEnabledProp;
    this.itemsCSVToExport = this.itemsCSVToExportProp || [];
    this.fieldsToCSVExport = this.fieldsToCSVExportProp || {};
    this.filename = this.filenameProp || 'Documento';
  }, 
  methods:{
    PDFClicked(){
        this.$emit("PDFClicked");
    }
  }
}
</script>